<template>
  <div class="app-home">
    <div class="app-home-head">
      COA Import
      <span
        style="position: absolute; left: 10px; cursor: pointer"
        @click="back"
      >
        <i class="el-icon-arrow-left"></i>
      </span>
      <div class="app-home-head-username">
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ username }}
            <i class="el-icon-arrow-down"></i>
            <!-- <span class="user-name">语言切换</span> -->
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="confirm"
                >Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>

    <div class="app-home-table">
      <div class="table-name">
        <span>中文Coa列表</span>
        <el-button type="primary" @click="toWorkList">
          <template>
            <span>Working List</span>
          </template>
        </el-button>
        <el-button type="primary" @click="handleClick">
          <template>
            <i class="el-icon-plus"></i>
            <span>新增</span>
          </template>
        </el-button>
      </div>

      <el-row style="padding: 10px">
        <el-col :span="5">
          <span>产品名称：</span>
          <el-autocomplete
            clearable
            v-model="searchData.name"
            :fetch-suggestions="nameSearch"
            placeholder="Please Input"
            popper-class="select-option"
            :popper-append-to-body="false"
            @input="handleSelectName"
          ></el-autocomplete>
        </el-col>
        <el-col :span="4">
          <span>批号：</span>
          <el-autocomplete
            clearable
            v-model="searchData.lot"
            :fetch-suggestions="lotSearch"
            placeholder="Please Input"
            popper-class="select-option"
            :popper-append-to-body="false"
            @input="handleSelectLot"
          ></el-autocomplete>
        </el-col>
        <el-col :span="5">
          <div>
            <span>产品编号：</span>
            <el-autocomplete
              popper-class="select-option"
              :popper-append-to-body="false"
              clearable
              v-model="searchData.code"
              :fetch-suggestions="codeSearch"
              placeholder="Please Input"
              @input="handleSelectCode"
            ></el-autocomplete>
          </div>
        </el-col>
        <!-- <el-col :span="4">
          <div style="margin-right: 20px">
            <el-radio-group
              v-model="dataType"
              size="medium"
              @change="dataTypeChange"
            > 
              <el-radio-button label="COA"></el-radio-button>
            </el-radio-group>
          </div>
        </el-col> -->
        <el-col :span="6">
          <div>
            <!-- <el-upload
              action=""
              :auto-upload="false"
              :on-change="onChange"
              :limit="1"
              style="float: left; margin: 0px 10px 0px 0px"
            >
              <el-button type="success">Upload</el-button>
            </el-upload> -->

            <el-button
              @click="handleSearch('查询')"
              type="primary"
              style="float: left"
              >Search</el-button
            >
            <el-button @click="handleSearch('重置')" style="float: left"
              >Reset</el-button
            >
          </div>
        </el-col>
      </el-row>

      <div
        :class="[{ 'table-list': true }, screen_ratio]"
        v-loading.fullscreen.lock="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-text="Desperately loading..."
        style="height: 77vh"
      >
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
        >
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            type="index"
            label="序号"
            width="80"
          ></el-table-column>

          <el-table-column
            prop="productName"
            label="产品名称"
          ></el-table-column>

          <el-table-column prop="itemCode" label="产品编号">
            <template slot="header">
              <span>Item Code</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': itemCodeSort,
                }"
                @click="handleSort('itemCode')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>

            <template slot-scope="scope">
              <!-- <span :style="{ color: isRepeat(scope.row.itemCode) }">{{ -->
              <span>{{ scope.row.itemCode }}</span>
            </template>
          </el-table-column>

          <el-table-column label="内销订单号/样品编号" width="220">
            <template slot-scope="scope">
              <span>{{scope.row.params.OrderNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="lot"
            label="批号"
            width="220"
          ></el-table-column>
          <el-table-column
            prop="verifiedTime"
            label="完成时间"
            width="140"
          >
            <template slot="header">
              <span>完成时间</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': verifiedTimeSort,
                }"
                @click="handleSort('verifiedTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.verifiedTime) }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="审核" width="100">
            <template slot-scope="scope">
              <i
                :class="verify_show(scope.row).icon"
                :style="{ color: verify_show(scope.row).color }"
              ></i>
            </template>
          </el-table-column> -->
          <el-table-column prop="completeTime" label="审核时间" width="140">
            <template slot="header">
              <span>审核时间</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': completeTimeSort,
                }"
                @click="handleSort('completeTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.completeTime) }}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="180px">
            <template slot-scope="scope">
              <span class="table-edit" @click="handleClick(scope.row, '编辑')">Edit</span>
              <span v-if="userInfo.operateStatus" class="table-edit" @click="copyItem(scope.row)">Copy</span>
              <span
                v-if="
                  username === 'JamesChen' ||
                  username === 'admin' ||
                  username === 'JiaZhang'
                "
                class="table-edit"
                style="color: red; margin-right: 8px"
                @click="deleteItem(scope.row)"
                >Delete</span>
                <i v-if="scope.row.verifiedStatus==1" class="el-icon-s-flag" style="color:red"></i>
            </template>
          </el-table-column>
        </el-table>

        <div class="table-pagition">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.pageIndex"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import xlsx from "xlsx";
export default {
  name: "home",
  mixins: [base],
  data() {
    return {
      loading: false,
      tableData: [],
      total: 0,
      params: {
        pageIndex: 1,
        pageSorts: [
          {
            column: "createTime",
            asc: false,
          },
        ],
        pageSize: 100,
        itemCode: "",
        productName: "",
        lot: "",
        isWorkList:false
      },
      searchData: {
        name: "",
        code: "",
        lot: ""
      },
      preview: {},
      createTimeSort: true,
      verifiedTimeSort: true,
      completeTimeSort: true,
      updateTimeSort: true,
      itemCodeSort: true,
      screen_ratio: "",
      nameList: [],
      lotList: [],
      codeList: [],
      getSearchList: true,
      uploadItemList: [
        {
          lotNumber: "",
          itemCode: "",
          productName: "",
          chinaLotNumber: "",
          shippingMethod: "",
          tyNumber: "",
          quantityKG: "",
          shippingDate: "",
          standard1: "",
          standard2: "",
          standard3: "",
          meshSize:"",
          lossOnDrying: "",
          bulkDensity: "",
          totalHeavyMetals: "",
          color: "",
          totalPlateCount: "",
          yeastAndMold: "",
          specicalField1:"",
          specicalField2:"",
          specicalField3:"",
          specicalField4:"",
          specicalField5:"",
          chinaProductName:"",
        },
      ],
      dataType:"COA",
      selectCoaInfoList:[],
    };
  },
  computed: {
    username() {
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      localStorage.setItem("loginUserNameKey", userInfo.username);
      if (userInfo) {
        return userInfo.username;
      } else {
        return "";
      }
    },
  },
  methods: {
    verify_show(val) {
      switch (val.verifiedStatus) { //是否验证与审核 0.已验证已审核 1已验证未审核 2未验证
        case 1:
          return { icon: "el-icon-s-flag", color: "#f56c6c" };
        case 0:
          return { icon: "el-icon-success", color: "#67c23a" };
        default:
          return { icon: "el-icon-info", color: "#a6a9ad" };
      }
    },
    toWorkList(){
      this.$router.push({path: "cn-worklist"});
    },
    handleSelectionChange(selection) {
      this.selectCoaInfoList = selection.map(item => item)
      console.log("this.selectCoaInfoList")
      console.log(this.selectCoaInfoList)
    },
    handleSelectName() {
      // this.handleSearch("查询");
    },
    handleSelectLot() {
      // this.handleSearch("查询");
    },
    handleSelectCode() {
      // this.handleSearch("查询");
    },
    nameSearch(queryString, cb) {
      // var restaurants = this.tableData.map(v => {
      //   return { value: v.productName };
      // });
      var restaurants = this.nameList;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    lotSearch(queryString, cb) {
      var codeList = JSON.parse(localStorage.getItem("itemCodeCnCoaListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.lot });
      });
      // var restaurants = this.lotList;
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    codeSearch(queryString, cb) {
      var codeList = JSON.parse(localStorage.getItem("itemCodeCnCoaListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      // var restaurants = localStorage.getItem('itemCodeListKey');
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    isRepeat(val) {
      let isRepeat = 0;
      this.tableData.forEach((v) => {
        if (val == v.itemCode) {
          isRepeat++;
        }
      });
      if (isRepeat > 1) {
        return "red";
      } else {
        return "black";
      }
    },
    getTime(time) {
      if (time) {
        return this.formatTime(time, `MM-dd-yyyy hh:mm`);
      } else {
        return "";
      }
    },
    logout() {
      let token = localStorage.getItem("specToken");
      let id = JSON.parse(localStorage.getItem("specUserInfo")).id;
      this.$api
        .logout({ id, token })
        .then(() => {
          this.$router.replace({ path: "/login" });
          this.$message({ type: "success", message: "退出成功" });
          localStorage.clear("specToken");
          localStorage.clear("specUserInfo");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirm() {
      this.$confirm("你确定要退出吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        this.logout();
      });
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData(this.params);
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getData(this.params);
    },
    handleClick(val) {
      var productType = "TY-P";
      if (val.jsonValue) {
        var item = JSON.parse(val.jsonValue);
        productType = item.productType;
      }
      localStorage.setItem("searchDataCnCoaParamsKey", JSON.stringify(this.params));
      this.$router.push({
        path: "cn-coaDetail",
        query: { id: val.id, productType: productType },
      });
    },
    copyItem(val) {
      this.$confirm(
        "这将复制数据. 继续?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        this.$api.handleCnCoa
          .copyCnCoaItemUrl(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "复制成功" });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    deleteItem(val) {
      this.$confirm(
        "这将永久删除这条数据。继续吗？",
        "提示",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        this.$api.handleCnCoa
          .deleteCnCoaItem(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "删除成功" });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    getData(params) {
      this.loading = true;
      this.$api.handleCnCoa
        .getCnCoaTableUrl(params)
        .then((data) => {
          console.log(data);
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          
          for(var index in this.tableData){  
            this.tableData[index].params=JSON.parse(this.tableData[index].jsonValue);
          }
          console.log(this.tableData);
          this.loading = false;
          if (this.getSearchList) {
            this.nameList = data.records.map((v) => {
              return { value: v.productName };
            });
            this.lotList = data.records.map((v) => {
              return { value: v.lot };
            });
            this.codeList = data.records.map((v) => {
              return { value: v.itemCode };
            });
            this.getSearchList = false;
            this.$store.commit("setNameList", this.nameList);
            this.$store.commit("setCodeList", this.codeList);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleSearch(type) {
      switch (type) {
        case "重置":
          {
            this.searchData = { name: "", code: "" , lot: ""};
          }
          break;
        case "查询":
          {
            let { name, code, lot } = this.searchData;
            this.params.itemCode = code;
            this.params.productName = name;
            this.params.lot = lot;
            this.params.pageIndex = 1;

            // localStorage.setItem('searchParamKey',JSON.stringify({name,code}))
            this.getData(this.params);
          }
          break;
        default:
          break;
      }
    },
    handleSort(val) {
      switch (val) {
        case "verifiedTime":
          {
            this.verifiedTimeSort = !this.verifiedTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.verifiedTime).getTime();
              let bTime = new Date(b.verifiedTime).getTime();
              return this.verifiedTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "completeTime":
          {
            this.completeTimeSort = !this.completeTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.completeTime).getTime();
              let bTime = new Date(b.completeTime).getTime();
              return this.completeTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "createTime":
          {
            this.createTimeSort = !this.createTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.createTime).getTime();
              let bTime = new Date(b.createTime).getTime();
              return this.createTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "editTime":
          {
            this.updateTimeSort = !this.updateTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.updateTime).getTime();
              let bTime = new Date(b.updateTime).getTime();
              return this.updateTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "itemCode":
          {
            this.itemCodeSort = !this.itemCodeSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.itemCode;
              let bitemCode = b.itemCode;
              return this.itemCodeSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        default:
          break;
      }
    },
    getItemCodeListData(params) {
      this.$api
        .getItemCodeList(params)
        .then((data) => {
          localStorage.setItem("itemCodeListKey", JSON.stringify(data));
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    back() {
      this.$router.push({ path: "/cn-home" });
    },
    async onChange(file) {
      let dataBinary = await readFile(file.raw);
      let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true });
      let workSheet = workBook.Sheets[workBook.SheetNames[0]];

      const data = xlsx.utils.sheet_to_json(workSheet);
      console.info(data);
      // this.uploadItemList = [];
      // //console.log(data[0]);

      // data.filter((item) => {
      //   //console.log("循环==i==");
      //   //console.log(item);
      //   let Newitem = {};
      //   var i = 0;
      //   for (var t in item) {
      //     i++;
      //     // console.log(item[t]);
      //     // console.log(i);
      //     //console.log(t);
      //     if (i == 1) {
      //       Newitem.lotNumber = item[t];
      //     } else if (i == 2) {
      //       Newitem.itemCode = item[t];
      //     } else if (i == 3) {
      //       Newitem.productName = item[t];
      //     } else if (i == 4) {
      //       Newitem.chinaLotNumber = item[t];
      //     } else if (i == 5) {
      //       Newitem.shippingMethod = item[t];
      //     } else if (i == 6) {
      //       Newitem.tyNumber = item[t];
      //     } else if (i == 7) {
      //       Newitem.quantityKG = item[t];
      //     } else if (i == 8) {
      //       Newitem.shippingDate = item[t];
      //     } else if (i == 9) {
      //       Newitem.standard1 = item[t];
      //     } else if (i == 10) {
      //       Newitem.standard2 = item[t];
      //     } else if (i == 11) {
      //       Newitem.standard3 = item[t];
      //     }else if(i==12){
      //       Newitem.meshSize=item[t];
      //     }else if (i == 13) {
      //       Newitem.lossOnDrying = item[t];
      //     } else if (i == 14) {
      //       Newitem.bulkDensity = item[t];
      //     } else if (i == 15) {
      //       Newitem.totalHeavyMetals = item[t];//.replace("<","").replace("ppm","").replace(/ /g,"");
      //     } else if (i == 16) {
      //       Newitem.color = item[t];
      //     } else if (i == 17) {
      //       Newitem.totalPlateCount = item[t];//.replace("<","").replace("/g","").replace(/ /g,"");
      //     } else if (i == 18) {
      //       Newitem.yeastAndMold = item[t];//.replace("<","").replace("/g","").replace(/ /g,"");
      //     } else if (i == 19) {
      //       Newitem.specicalField1 = item[t];
      //     } else if (i == 20) {
      //       Newitem.specicalField2 = item[t];
      //     } else if (i == 21) {
      //       Newitem.specicalField3 = item[t];
      //     } else if (i == 22) {
      //       Newitem.specicalField4 = item[t];
      //     } else if (i == 23) {
      //       Newitem.specicalField5 = item[t];
      //     } else if (i == 24) {
      //       Newitem.chinaProductName = item[t];
      //     } 
      //   }

      //   // Newitem.lotNumber = item["Lot #"];
      //   // Newitem.itemCode = item["Item Code"];
      //   // Newitem.productName = item["Product Name"];
      //   // Newitem.chinaLotNumber = item["China Lot #"];
      //   // Newitem.shippingMethod = item["Shipping method"];
      //   // Newitem.tyNumber = item["TY#"];
      //   // Newitem.quantityKG = item["Quantity(kg)"];
      //   // Newitem.shippingDate = item["Shipping Date"];
      //   // Newitem.standard1 = item["Standard 1"];
      //   // Newitem.standard2 = item["Standard 2"];
      //   // Newitem.standard3 = item["Standard 3"];
      //   // Newitem.lossOnDrying = item["Loss On Drying"];
      //   // Newitem.bulkDensity = item["Bulk Density"];
      //   // Newitem.totalHeavyMetals = item["Total Heavy Metals"];
      //   // Newitem.color = item["Color"];
      //   // Newitem.totalPlateCount = item["Total Plate Count"];
      //   // Newitem.yeastAndMold = item["Yeast & Mold"];
      //   // Newitem.specicalField1=item["SpecicalField 1"];
      //   // Newitem.specicalField2=item["SpecicalField 2"];
      //   // Newitem.specicalField3=item["SpecicalField 3"];
      //   // Newitem.specicalField4=item["SpecicalField 4"];
      //   // Newitem.specicalField5=item["SpecicalField 5"];
      //   this.uploadItemList.push(Newitem);
      // });

      // console.log(this.uploadItemList);
      // this.$api.coa.uploadCoaItem(this.uploadItemList)
      //     .then(() => {
      //       this.$message({
      //         type: "success",
      //         message: "SuccessFul",
      //       });
      //       this.handleSearch("查询");
      //     })
      //     .catch((err) => {
      //       this.$message({
      //         type: "error",
      //         message: err,
      //       });
      //       console.log(err);
      //     });
    },
    dataTypeChange(){
      console.log("gg");
    },
    getItemCodeCnCoaListUrl(params) {
      this.$api.handleCnCoa
        .getItemCodeCnCoaListUrl(params)
        .then((data) => {
          localStorage.setItem("itemCodeCnCoaListKey", JSON.stringify(data));
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  mounted() {
    // this.getItemCodeListData({});
    this.getItemCodeCnCoaListUrl({});
  },
  created() {
    // let type = localStorage.getItem("listDataTypeKey");
    // if (type == null || typeof type == "undefined") {
    //   this.dataType = "Spec";
    // } else {
    //   this.dataType = type;
    // }
    console.info('-----this.getCnCoaData(this.params);---')
    let searchDataCnCoaParams = localStorage.getItem("searchDataCnCoaParamsKey");
    if(searchDataCnCoaParams){
      console.info(searchDataCnCoaParams)
      this.params = JSON.parse(searchDataCnCoaParams);
      this.searchData = { name: this.params.productName, code: this.params.itemCode, lot: this.params.lot };
    }
    this.getData(this.params);
    // this.getPreview();
    let sheight = window.screen.height;
    let swidth = window.screen.width;
    let ratio = swidth / sheight;
    switch (ratio) {
      case 16 / 9:
        this.screen_ratio = "ratio-16-9";
        break;
      case 16 / 10:
        this.screen_ratio = "ratio-16-10";
        break;
      case 3 / 2:
        this.screen_ratio = "ratio-3-2";
        break;
      case 4 / 3:
        this.screen_ratio = "ratio-4-3";
        break;
      default:
        this.screen_ratio = "ratio-16-10";
        break;
    }
  },
};
export const readFile = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (ev) => {
      resolve(ev.target.result);
    };
  });
};
</script>

<style lang="scss" scoped>
::v-deep .select-option {
  min-width: 300px;
}
.app-home {
  $size-16-9: 56vh;
  $size-16-10: 43vh;
  $size-4-3: 30vh;
  $size-3-2: 38vh;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .app-home-head {
    position: relative;
    background-color: #fff;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .app-home-head-username {
      font-size: 14px;
      color: #333;
      font-weight: 400;
      position: absolute;
      top: 0;
      right: 10px;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
    }
  }
  .app-home-preview {
    box-sizing: content-box;
    padding: 10px 120px;
    .preview-content {
      box-sizing: border-box;
      background-color: #fff;
      height: 80px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      > div {
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        > div:nth-of-type(1) {
          font-size: 14px;
          color: #999;
        }
        > div:nth-of-type(2) {
          margin-top: 10px;
          font-size: 22px;
          color: #000;
        }
      }
      > div:nth-of-type(2) {
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
      }
    }
  }
  .app-home-table {
    background-color: #fff;
    margin: 10px;
    // margin-top: 0;
    .table-name {
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 140px;
      padding-right: 34px;
      font-size: 20px;
      color: #000;
      border-bottom: 1px solid #e8e8e8;
    }

    .ratio-16-9 {
      ::v-deep .el-table__body-wrapper {
        height: $size-16-9;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-4-3 {
      ::v-deep .el-table__body-wrapper {
        height: $size-4-3;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-16-10 {
      ::v-deep .el-table__body-wrapper {
        height: 58vh;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-3-2 {
      ::v-deep .el-table__body-wrapper {
        height: $size-3-2;
        overflow-y: auto;
        border: none;
      }
    }
    .table-list {
      overflow: hidden;
      padding-left: 60px;
      padding-right: 34px;
      .table-edit {
        display: inline-block;
        border-radius: 3px;
        padding: 2px 6px;
        cursor: pointer;
        color: #1890ff;
        &:hover {
          background-color: rgba(225, 225, 225, 0.4);
        }
      }
      .table-edit:hover {
        opacity: 0.8;
      }
      .table-pagition {
        text-align: right;
        padding: 20px 0;
        margin-top: 20px;
        .el-pagination {
          display: inline-block;
        }
      }
    }
  }
  .table-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 300ms;
    display: inline-block;
    &:hover {
      color: #409eff;
    }
  }
  .table-icon-rotate {
    transform: rotateZ(180deg);
  }
}
</style>